import React, { useState } from "react"
import { FaGithub, FaFreeCodeCamp, FaLinkedin, FaRegCommentAlt, FaArrowAltCircleRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { IoMdArrowDropright, IoMdArrowDropdown } from "react-icons/io";
function  HomeContent() {


        const { t, i18n } = useTranslation();
        const [option, setOption] = useState('Specialiste Digital');
        const [open, setOpen] = useState(true);

    const options = ['Specialiste Digital', 'PMO', 'Développeur web'];

        return (
            <>
                <div className="lang">
                    <li><Link  to="/contact">< FaRegCommentAlt /></Link></li>
                </div>

                <div className="heroContent">

                <h1 className="heroTitle"> Antoine <span className="color">HOELLINGER</span></h1>

                    <div className="heroSubtitle">

                        <div className="hero_textcontainer ">
                            <h2>{t("Vous avez besoin d'un")}</h2>
                        </div>

                        <div className="dropping-texts" style={{ borderRadius: open ? '10px 10px 0px 0px' : '10px' }}>

                            <span onClick={() => setOpen(prev => !prev)} style={{ padding: '0px 20px',backgroundColor: '#14f3db',display: 'flex', alignItems: 'center', borderRadius: open ? '10px 10px 0px 0px' : '10px'}}>
                            {open ? <IoMdArrowDropdown /> : <IoMdArrowDropright />}
                            <h2>{t(option)}</h2>
                            </span>

                            <ul style={{ visibility: open ? 'visible' : 'hidden' }}>
                                {options
                                    .filter(item => (item !== option) )
                                    .map((item) => <li onClick={() => { setOpen(false);  setOption(item)  }}><span className='home_li_before'>&</span><span className=''>{item}</span> </li>)
                                }
                            </ul>
                            
                        </div>


                        <span style={{display:'flex', alignItems:'center', gap:'20px'}}>

                        <Link className="HomeButton"  to="/about">
                            <FaArrowAltCircleRight className="IconHomeButton" />
                        </Link>
                        </span>
                    </div>


                </div>

                <ul className="socialBlock">
                    <li><a href="https://www.freecodecamp.org/antoinehlg"><FaFreeCodeCamp /></a></li>
                    <li><a href="https://github.com/savemyfiles"><FaGithub /></a></li>
                    <li><a href="https://www.linkedin.com/in/antoine-hoellinger/"><FaLinkedin /></a></li>
                </ul>

            </>
        )
    }




export default HomeContent

