import React from "react"
import { useTranslation } from "react-i18next";



function Skills(props) {

    const { t, i18n } = useTranslation();

    const tools = [
        {
            title: 'SALESFORCE',
            link: "https://www.salesforce.com/fr/",
            image: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/f9/Salesforce.com_logo.svg/640px-Salesforce.com_logo.svg.png",
        },
        {
            title: 'ZENDESK',
            link: "https://www.zendesk.fr/",
            image: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/75/Zendesk_primary_logo_espresso.svg/640px-Zendesk_primary_logo_espresso.svg.png",
        },
        {
            title: 'EXCEL & QUERY',
            link: "https://www.microsoft.com/fr-fr/microsoft-365/excel",
            image: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/34/Microsoft_Office_Excel_%282019%E2%80%93present%29.svg/640px-Microsoft_Office_Excel_%282019%E2%80%93present%29.svg.png",
        },
        {
            title: 'POWER BI',
            link: "https://powerbi.microsoft.com/fr-fr/",
            image: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/cf/New_Power_BI_Logo.svg/640px-New_Power_BI_Logo.svg.png",
        },
        {
            title: 'TABLEAU SOFTWARE',
            link: "https://www.tableau.com/fr-fr",
            image: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4b/Tableau_Logo.png/640px-Tableau_Logo.png",
        },
        {
            title: 'DEVELOPPEMENT WEB',
            link: "https://developer.mozilla.org/fr/docs/Learn/Front-end_web_developer",
            image: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/99/Unofficial_JavaScript_logo_2.svg/640px-Unofficial_JavaScript_logo_2.svg.png",
        },
        {
            title: 'JIRA',
            link: "https://www.atlassian.com/software/jira",
            image: "https://upload.wikimedia.org/wikipedia/commons/thumb/8/82/Jira_%28Software%29_logo.svg/1200px-Jira_%28Software%29_logo.svg.png",
        },
        {
            title: 'AZURE DEVOPS',
            link: "https://azure.microsoft.com/fr-fr/services/devops/",
            image: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a8/Microsoft_Azure_Logo.svg/1200px-Microsoft_Azure_Logo.svg.png",
        },
        {
            title: 'GIT HUB',
            link: "https://github.com/",
            image: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/91/Octicons-mark-github.svg/1200px-Octicons-mark-github.svg.png",
        }
    ];

    const skills = [
        {
            title: 'Appétence IT',
            percent: 100,
        },
        {
            title: 'Gestion et Suivi de Portefeuille projets',
            percent: 95,
        }, {
            title: 'REPORTING & DOCUMENTATION DES PROJETS',
            percent: 90,
        }, {
            title: 'Cadrage, Test, Recette & MEP de logiciels',
            percent: 90,
        }, {
            title: 'Gestion de base de données',
            percent: 70,
        }, {
            title: 'Developpement front-end',
            percent: 70,
        }, {
            title: 'Developpement back-end',
            percent: 80,
        }
    ];



    return (

        <>
           <div className="title">
                <h1>{t("Mes services")}</h1>
                <div className="subline"></div>
            </div>

            <div className="containerSkills">

                <div className="skills">
                    <div className="subskills">
                        <h3>{t('Compétences')}</h3>

                        {skills.map((item, index) => (
                            <>
                           <div className="details">
                            <span><p>{ t(item.title) }</p></span>
                            <span><p>{ item.percent} %</p></span>
                        </div>
                        <div className="bar_skill">
                            <div id={`html-bar-${item.percent}`}></div>
                                </div>
                            </>
                        ))}

                    </div>
                </div>


                <div className="tools">

                    <h3>{t('Outils maitrisés')}</h3>

                    <div className="tools-table">

                    {tools.map((tool, index) => (
                        <div key={tool.title} className="tools_card" onClick={() => window.open(tool.link, '_blank', 'noopener,noreferrer')} >
                            <div className="tools_card_img">
                                    <img alt={`Logo d'illustration pour ${tool.title}`} src={tool.image} />
                            </div>
                            <div className="tools_card_text">
                                <p>{t(tool.title)}</p>
                            </div>
                        </div>
                    )) }
            

                </div>
                </div>

            </div>
            </>
        )
    }


export default Skills